import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import { Account } from '@/features/dashboard/interface';

interface UserAccount {
  address: string | undefined;
  bornDay: string | undefined;
  city: string | undefined;
  email: string | undefined;
  emailVerified: boolean | undefined;
  firstDeposit: boolean | undefined;
  fullProfile: boolean | undefined;
  kycLevel: number | undefined;
  kycStatus: number | undefined;
  lastName: string | undefined;
  name: string | undefined;
  nick: string | undefined;
  phone: string | undefined;
  provider: number | undefined;
  topup_id: string | undefined;
  contingencies: {
    casino: boolean | undefined;
    bets: boolean | undefined;
    deposits: boolean | undefined;
    withdrawals: boolean | undefined;
  },
}

const AccountInitialState: UserAccount = {
  address: undefined,
  bornDay: undefined,
  city: undefined,
  email: undefined,
  emailVerified: undefined,
  firstDeposit: undefined,
  fullProfile: undefined,
  kycLevel: undefined,
  kycStatus: undefined,
  lastName: undefined,
  name: undefined,
  nick: undefined,
  phone: undefined,
  provider: undefined,
  topup_id: undefined,
  contingencies: {
    casino: undefined,
    bets: undefined,
    deposits: undefined,
    withdrawals: undefined,
  },
};

export interface AuthState {
  account: UserAccount;
  setAccount: (data: UserAccount) => void;

  accounts: Account[];
  addAccount: (currentAccount: Account[]) => void;
  clearAccounts: () => void;
  removeAccount: (account: Account) => void;

  balance: any[];
  setBalance: (value: any[]) => void;

  isxtremepushSet: boolean;
  setXtremepush: () => void;

  clearStore: () => void;

  notifyAccepted: boolean;
  setNotifyAccepted: () => void;
  selectAccount: (account: Account | null) => void;
  selectedAccount: Account | null;
  clearSelectedAccount: () => void;

  btnStateMetaMap: {
    status: boolean;
    date: Date | null;
  };
  setBtnStateMetaMap: (btnStateMetaMap: { status: boolean; date: Date | null }) => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => {
      return {
        // To current user account
        account: AccountInitialState,
        setAccount: (data: UserAccount) => { set({ account: data }); },
        // End, current user account

        // To accounts
        accounts: [],
        addAccount: (currentAccount: Account[]) => {
          return set({ accounts: currentAccount });
        },
        clearAccounts: () => {
          return set({ accounts: [] });
        },
        removeAccount: (currentAccount: Account) => {
          const accounts: Account[] = get().accounts.filter((account) => {
            return (account.bank !== currentAccount.bank || account.number !== currentAccount.number || account.type !== currentAccount.type);
          });
          set({ accounts });
        },
        // End, to accounts

        // To balance
        balance: [],
        setBalance: (value: any[]) => { set({ balance: value }); },
        // End, to balance

        // To isxtremepushSet
        isxtremepushSet: false,
        setXtremepush: () => { set({ isxtremepushSet: true }); },
        // End, to isxtremepushSet

        // To clear store
        clearStore: () => {
          // localStorage.removeItem('IT_account');
          set({
            account: AccountInitialState,
            accounts: [],
            balance: [],
            isxtremepushSet: false,
            notifyAccepted: false,
          });
        },
        // End, to clear store

        // To notifyAccepted
        notifyAccepted: false,
        setNotifyAccepted: () => { set({ notifyAccepted: true }); },
        // End, to notifyAccepted
        selectAccount: (account: Account | null) => {
          if (account) {
            return set({ selectedAccount: account });
          }
          return set({ selectedAccount: null });
        },
        selectedAccount: null,
        clearSelectedAccount: () => {
          return set({ selectedAccount: undefined });
        },

        btnStateMetaMap: {
          status: false,
          date: null,
        },
        setBtnStateMetaMap: (btnStateMetaMap: { status: boolean; date: Date | null }) => { return set(() => { return { btnStateMetaMap }; }); },
      };
    },
    {
      name: 'IT_account',
      storage: createJSONStorage(() => { return localStorage; }),
    },
  ),
);
